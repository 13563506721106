

import { CF2Component } from 'javascript/lander/runtime'

export default class VideoBackgroundV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    this.parentElement = this.element.parentElement
    this.isPageBackground = this.parentElement.getAttribute('data-page-element') === 'ContentNode'
    this.background = $(this.parentElement).css('background')
    this.backgroundOverlayContainer = this.element.querySelector('.backgroundOverlay')
    this.videoEnded = false

    // data-params
    this.id = this.element.getAttribute('data-param-videoid')
    this.playerId = this.element.getAttribute('data-param-videoplayerid')
    this.type = this.element.getAttribute('data-param-videotype')
    this.endaction = this.element.getAttribute('data-param-endaction')
    this.redirectUrl = this.element.getAttribute('data-param-redirecturl')
    this.redirectToNewPage = this.element.getAttribute('data-param-redirectnewpage')
    this.useBgAsOverlay = this.element.getAttribute('data-param-usebgasoverlay')
    this.styleType = this.element.getAttribute('data-param-bgstyletype')
    this.offsetY = this.element.getAttribute('data-param-bgoffsety')
    this.offsetX = this.element.getAttribute('data-param-bgoffsetx')
    this.hideOnMobile = this.element.getAttribute('data-param-hideonmobile')
    this.overlayBackground = this.element.getAttribute('data-param-overlaybackground')

    const href = window.location.href
    const queries = ($).parseParams(href)
    if (queries['noautoplay'] == 'true') return

     if (this.type === 'youtube') {
      if (!document.getElementById('youtube-script')) {
        const tag = document.createElement('script')
        tag.src = 'https://www.youtube.com/iframe_api'
        tag.id = 'youtube-script'
        const firstScriptTag = document.getElementsByTagName('script')[0]
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
      }
      this.handleYoububePlayer()
    } else if (this.type === 'vimeo') {
      if (!document.getElementById('vimeo-script')) {
        const tag = document.createElement('script')
        tag.src = 'https://player.vimeo.com/api/player.js'
        tag.id = 'vimeo-script'
        $('body').append(tag)
      }
      this.handleVimeoPlayer()
    }
  }

  handleVideoStart() {
    const parentElement = this.parentElement
    const useBgAsOverlay = this.useBgAsOverlay === 'true'
    const background = this.overlayBackground
    const backgroundOverlayContainer = this.backgroundOverlayContainer
    const zIndex = $(parentElement).css('z-index')
    $(parentElement).css('background', 'none')
    if (useBgAsOverlay) {
      $(backgroundOverlayContainer).css('display', 'block')
      $(backgroundOverlayContainer).css('background', background)
    }
    if (!zIndex || zIndex === 'auto') {
      $(parentElement).css('z-index', 1)
    }
    this.handleVideoResize()
  }

  handleYoububePlayer() {
    const loop = this.endaction === 'loop' ? 1 : 0
    const videoId = this.id
    const playerId = this.playerId
    const [width, height] = this.getVideoSize()

    const onPlayerStateChange = (evt) => {
      if (evt.data == window.YT.PlayerState.ENDED) {
        this.handleVideoEndAction()
      }
    }
    const onPlayerReady = () => {
      this.handleVideoStart()
    }

    const handleYoutube = (timeout) => {
      timeout = timeout >= 300 ? timeout : timeout + 50
      if (window.YT?.Player) {
        const player = new window.YT.Player(playerId, {
          width: width,
          height: height,
          videoId: videoId,
          playerVars: {
            controls: 0,
            autoplay: 1,
            mute: 1,
            disablekb: 1,
            loop: loop,
            enablejsapi: 1,
            modestbranding: 1,
            playlist: videoId,
            origin: window.location.href,
            rel: 0,
          },
          events: {
            'onReady': onPlayerReady,
            'onStateChange': onPlayerStateChange,
          },
        })
      } else {
        window.setTimeout(handleYoutube, timeout, timeout)
      }
    }
    handleYoutube(50)
  }

  handleVimeoPlayer() {
    const videoId = this.id
    const loop = this.endaction === 'loop'
    const playerId = this.playerId
    const [width, height] = this.getVideoSize()

    const handleVimeo = (timeout) => {
      timeout = timeout >= 300 ? timeout : timeout + 50
      if (window.Vimeo?.Player) {
        $(`#${playerId}`).css({
          overflow: 'hidden',
          width: '100%',
          height: '100%',
        })
        const player = new window.Vimeo.Player(playerId, {
          id: videoId,
          width: width,
          height: height,
          autoplay: true,
          muted: true,
          pause: false,
          byline: false,
          title: false,
          loop: loop,
          autopause: false,
          controls: false,
          sidedock: false,
        })
        player.on('play', () => {
          this.handleVideoStart()
        })
        player.on('ended', () => {
          this.handleVideoEndAction()
        })
      } else {
        window.setTimeout(handleVimeo, timeout, timeout)
      }
    }

    handleVimeo(50)
  }

  getVideoSize() {
    const videoRatio = 16 / 9
    let width, height
    let parentWidth, parentHeight
    let isHeightBiggerThanWidth = false
    if (this.styleType === 'fill') {
      width = '100%'
      height = '100%'
    } else {
      const [paddingLeft] = $(this.parentElement).css('padding-left').split('px') ?? 0
      const [paddingRight] = $(this.parentElement).css('padding-right').split('px') ?? 0
      const [paddingTop] = $(this.parentElement).css('padding-top').split('px') ?? 0
      const [paddingBottom] = $(this.parentElement).css('padding-bottom').split('px') ?? 0
      const [borderWidth] = $(this.parentElement).css('border-width').split('px') ?? 0
      if (this.isPageBackground) {
        parentWidth = $(window).width()
        parentHeight = $(window).height()
      } else {
        parentWidth = Number($(this.parentElement).width()) + Number(paddingLeft) + Number(paddingRight) + Number(borderWidth)
        parentHeight = Number($(this.parentElement).height()) + Number(paddingBottom) + Number(paddingTop) + Number(borderWidth)
      }
      if (parentWidth / videoRatio < parentHeight) {
        isHeightBiggerThanWidth = true
        width = parentHeight * videoRatio
        height = parentHeight
      } else {
        width = parentWidth
        height = parentWidth / videoRatio
      }
    }
    return [width, height, parentWidth, parentHeight, isHeightBiggerThanWidth]
  }

  handleVideoResize() {
    const element = this.element
    const parentElement = this.parentElement
    const iframe = $(element).find('iframe')
    const isPageBackground = this.isPageBackground
    const styleType = this.styleType
    const offsetY = this.offsetY
    const offsetX = this.offsetX
    const hideOnMobile = this.hideOnMobile === 'true'
    const useBgAsOverlay = this.useBgAsOverlay === 'true'
    const background = this.background

    if (!isPageBackground) {
      $(element).css({
        position: 'absolute',
        overflow: 'hidden',
      })
      $(parentElement).css({
        position: 'relative',
      })
    }

    const resizeVideo = () => {
      if (!this.videoEnded) {
        if ($(window).width() < 700 && hideOnMobile) {
          $(element).css({
            display: 'none'
          })
          $(parentElement).css('background', background)
          return
        } else {
          $(element).css({
            display: 'block'
          })
          $(parentElement).css('background', 'none')
        }
        const videoRatio = 16 / 9
        const [width, height, parentWidth, parentHeight, isHeightBiggerThanWidth] = this.getVideoSize()
        const widthDiff = width - parentWidth
        const heightDiff = height - parentHeight
        if (styleType === 'offset') {
          const heightDiff = height - parentHeight
          const widthDiff = width - parentWidth
          const fixedOffsetX = (widthDiff * offsetX) / 100
          const fixedOffsetY = (heightDiff * offsetY) / 100
          if (isHeightBiggerThanWidth) {
            iframe.width(width).height(height).css({
              'transform': `translateX(-${fixedOffsetX}px)`,
              'pointer-events': 'none',
            })
          } else {
            iframe.width(width).height(height).css({
              'transform': `translateY(-${fixedOffsetY}px)`,
              'pointer-events': 'none',
            })
          }
        } else {
          iframe.width(width).height(height).css({
            background: 'black',
            'pointer-events': 'none',
          })
        }
      }
    }
    resizeVideo()
    window.addEventListener('resize', function () {
      resizeVideo()
    })
  }

  handleVideoEndAction() {
    const element = this.element
    const parentElement = this.parentElement
    const endAction = this.endaction
    const redirectUrl = this.redirectUrl
    const redirectNewPage = this.redirectToNewPage === 'true'
    const background = this.background

    if (endAction !== 'loop') {
      this.videoEnded = true
      $(element).css({
        display: 'none',
      })
      $(parentElement).css('background', background)
    }
    if (endAction === 'redirect') {
      if (redirectNewPage) {
        window.open(redirectUrl, '_blank')
      } else {
        window.location.href = redirectUrl
      }
    } else if (endAction === 'open-popup') {
      globalThis.CFOpenPopup()
    }
  }



}

window["VideoBackgroundV1"] = VideoBackgroundV1


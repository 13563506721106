
          
          
          class VideoPlayerStarterV1 {
    static getInstance() {
      if (this._instance) {
        return this._instance
      }
      this._instance = new VideoPlayerStarterV1();
      this.setupYoutubeAPI();
      this.setupVimeoAPI();
      this.setupWistiaAPI();
      this.setupVoomlyAPI();

      return this._instance
    }

    static setupYoutubeAPI() {
      const youtubeVideos = document.querySelectorAll('[data-video-type="youtube"]')
      youtubeVideos?.forEach((yVideo) => {
        if (yVideo?.querySelector('.iframeblocked, .iframeunmuted') && !document.getElementById('youtube-script')) {
          const tag = document.createElement('script')
          tag.src = '//www.youtube.com/iframe_api'
          tag.id = 'youtube-script'
          const firstScriptTag = document.body.getElementsByTagName('script')[0]
          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
          globalThis.onYouTubePlayerAPIReady = () => {
            this.YoutubeApiReady = true;
            const event = new CustomEvent('CF2_YOUTUBE_API_READY')
            document.dispatchEvent(event)
          }
        }
      })
    }

    static setupVimeoAPI() {
      const vimeoVideos = document.querySelectorAll('[data-video-type="vimeo"]')
      vimeoVideos?.forEach((vVideo) => {
        if (vVideo?.querySelector('.iframeblocked, .iframeunmuted') && !document.getElementById('vimeo-script')) {
          const tag = document.createElement('script')
          tag.src = 'https://player.vimeo.com/api/player.js'
          tag.id = 'vimeo-script'
          const firstScriptTag = document.getElementsByTagName('script')[0]
          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
        }
      })
    }

    static setupWistiaAPI() {
      const wistiaVideos = document.querySelectorAll('[data-video-type="wistia"]')
      wistiaVideos?.forEach((wVideo) => {
        if (wVideo?.querySelector('.iframeblocked, .iframeunmuted') && !document.getElementById('wistia-script')) {
          const tag = document.createElement('script')
          tag.src = '//fast.wistia.net/assets/external/E-v1.js'
          tag.id = 'wistia-script'
          const firstScriptTag = document.head.getElementsByTagName('script')[0]
          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
        }
      })
    }

    static setupVoomlyAPI() {
      if (document.querySelectorAll('[data-video-type="voomly"]').length) {
        document.querySelectorAll('[data-video-type="voomly"]').forEach((voomlyVideo) => {
          const tagSrc = voomlyVideo.getAttribute('data-src-lib-script')
          voomlyVideo.removeAttribute('data-src-lib-script')
          if (!document.getElementById('voomly-script')) {
            const tag = document.createElement('script')
            tag.src = tagSrc
            tag.id = 'voomly-script'
            const firstScriptTag = document.getElementsByTagName('script')[0]
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
            tag.onload = () => {
              document.dispatchEvent(new CustomEvent('CF2_VOOMLY_API_READY'))
            }
          }
        })
      }
    }

    setupVideo(element) {
      const videoType = element.getAttribute('data-video-type')
      if (videoType === 'html5') {
        this.setupHTML5(element);
      } else if (videoType === 'youtube') {
        this.setupYoutube(element);
      } else if (videoType === 'vimeo') {
        this.setupVimeo(element);
      } else if (videoType === 'wistia') {
        this.setupWistia(element);
      } else if (videoType === 'voomly'){
        this.setupVoomly(element);
      } else if( videoType === 'custom' || videoType === 'custom_script') {
        this.sharedSetup(element);
      }
      this.setupLazyLoaded(element)
    }

    setAndGetIFrameId(element) {
      let frameId = null
      if (!element) return
      const iframe = element.querySelector('iframe')
      frameId = iframe.getAttribute('name')
      iframe.setAttribute('id', frameId)
      return frameId
    }

    fitVids(element) {
      const customSelectors = [
        'iframe[src*="fast.wistia.net"]',
        'iframe[src*="embed.voomly.com"]',
        'iframe[data-src*="player.vimeo.com"]',
        'iframe[data-src*="youtube.com"]',
        'iframe[data-src*="youtube-nocookie.com"]',
        'iframe[data-src*="fast.wistia.net"]',
        'iframe[data-src*="embed.voomly.com"]',
        'iframe[data-src*="kickstarter.com"][data-src*="video.html"]',
      ]
      $(element).fitVids({ customSelector: customSelectors.join(',') })
    }

    setupMuted(bpPlayer, element) {
      if (!bpPlayer || !element) return
      const iframeUnmute = element.querySelector('.iframeUnmute')
      const child = '<div class="unmute-player-wrapper"><span class="unmute-player-icon"><i class="fa fa-volume-up"/></span></div>'
      $(iframeUnmute).append(child)
    }

    handleExtraListeners(element, bpPlayer, callback) {
      const videoType = element.getAttribute('data-video-type')
      const blockPauseAttr = element.getAttribute(`data-${videoType}-block-pause`)
      if (blockPauseAttr === 'true') {
        this.appendBPText(bpPlayer, element)
        const wrapper = element.querySelector('.start-session-wrapper')
        wrapper.addEventListener('click', () => {
          callback()
          $(wrapper).remove()
        })
      } else {
        this.setupMuted(bpPlayer, element)
        const wrapper = element.querySelector('.unmute-player-wrapper')
        wrapper.addEventListener('click', () => {
          callback()
          $(wrapper).closest('.iframeUnmute').remove()
        })
      }
    }

    resizeVids(iframe, element) {
      $(iframe).attr('width', 'auto')
      $(iframe).attr('height', 'auto')
      this.fitVids(element)
      $(iframe).css('width', '100%')
      $(iframe).css('height', '100%')
    }

    setStickyVideo(element, isStickyCloseable, videoFrame) {
      const { bottom, top } = element.getBoundingClientRect()
      if (bottom <= 10) {
        videoFrame.classList.add('video-sticky-css')
        if (!isStickyCloseable) return
        const closeIconDiv = element.getElementsByClassName('closeIconDiv')[0]
        closeIconDiv.style.display = 'flex'
      }
      if (top >= 10) {
        videoFrame.classList.remove('video-sticky-css')
        if (!isStickyCloseable) return
        const closeIconDiv = element.getElementsByClassName('closeIconDiv')[0]
        closeIconDiv.style.display = 'none'
      }
    }

    appendBPText(bpPlayer, element) {
      if (!bpPlayer || !element) return
      const text = element.getAttribute('data-session-starter-text') ?? 'Click To Join Session In Progess...'
      const iframeBlocker = element.querySelector('.iframeBlocker')
      const child = `<div class="start-session-wrapper"><span class="start-session">${text}</span></div>`
      $(iframeBlocker).append(child)
    }

    appendExtraChildren(element) {
      const elVideo = element.querySelector('.elVideo')
      if (elVideo?.classList?.contains('iframeblocked')) $(elVideo).append("<div class='iframeBlocker'></div>")
      if (elVideo?.classList?.contains('iframeunmuted')) $(elVideo).append("<div class='iframeUnmute'></div>")
    }

    setupDefaultSize(element) {
      const iframe = element.querySelector('iframe')
      if (!iframe) return

      const isVideoPopup = element.parentElement?.classList?.contains('elPopupVideoContainer')
      if (isVideoPopup) {
        this.fitVids(element)
      } else {
        const wrapperWidth = $(element).css('width').split('px')[0]
        const customWidth = element.getAttribute('data-custom-width')
        const customHeight = element.getAttribute('data-custom-height')
        const hasCustomSize = !!customHeight || !!customWidth
        if (Number(wrapperWidth) > Number(customWidth)) {
          if (hasCustomSize) {
            if (customHeight == 'auto' && customWidth == 'auto') {
              this.fitVids(element)
            }
          } else {
            this.fitVids(element)
          }
        } else {
          this.fitVids(element)
        }
      }
    }

    setupVideoReSizing(element) {
      const iframe = element.querySelector('iframe')
      const isVideoPopup = element.parentElement?.classList?.contains('elPopupVideoContainer')
      const customWidth = element.getAttribute('data-custom-width')
      if (!iframe || isVideoPopup || !customWidth) return
      const customHeight = element.getAttribute('data-custom-height')
      const fluidClass = 'fluid-width-video-wrapper'
      window.addEventListener('resize', () => {
        const wrapperWidth = $(element).css('width').split('px')[0]
        const hasResized = element.querySelector(`.${fluidClass}`)
        if (Number(wrapperWidth) < Number(customWidth)) {
          if (!hasResized) {
            this.resizeVids(iframe, element)
          }
        } else {
          if (!hasResized) return
          const $fluidWrapper = $(element).find(`.${fluidClass}`)
          $fluidWrapper.css('padding-top', 0)
          $fluidWrapper.css('border-radius', 'inherit')
          $(iframe).css('width', customWidth ?? 640)
          $(iframe).css('height', customHeight ?? 360)
          $fluidWrapper.removeClass(fluidClass)
        }
      })
    }

    setupStickyVideo(element) {
      const isSticky = element.getAttribute('data-is-video-sticky') === 'true'
      if (!isSticky) return
      let isVideoClosed = false
      const isMobile = {
        Android: function () {
          return navigator.userAgent.match(/Android/i)
        },
        BlackBerry: function () {
          return navigator.userAgent.match(/BlackBerry/i)
        },
        iOS: function () {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i)
        },
        Opera: function () {
          return navigator.userAgent.match(/Opera Mini/i)
        },
        Windows: function () {
          return navigator.userAgent.match(/IEMobile/i)
        },
        any: function () {
          return isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows()
        },
      }
      const isStickyCloseable = element.getAttribute('data-sticky-closeable') === 'true'
      let videoFrame = element.querySelector('iframe')
      const videoType = element.getAttribute('data-video-type')
      if (videoType === 'html5') videoFrame = element.getElementsByClassName('vjs-default-skin')
      if (!videoFrame) return
      if (isStickyCloseable) {
        const videoWrapper = element.getElementsByClassName('elVideo')[0]
        const closeVideoDiv = document.createElement('div')
        const closeVideoIcon = document.createElement('i')
        closeVideoDiv.classList.add('video-sticky-close-button-css')
        closeVideoIcon.className = 'fa fa-times'
        closeVideoDiv.appendChild(closeVideoIcon)
        closeVideoDiv.classList.add('closeIconDiv')
        videoWrapper.appendChild(closeVideoDiv)

        const closeIconDiv = videoWrapper.getElementsByClassName('closeIconDiv')[0]
        closeIconDiv.addEventListener('click', function (e) {
          e.preventDefault()
          closeIconDiv.style.display = 'none'
          videoFrame.classList.remove('video-sticky-css')
          isVideoClosed = true
        })
      }
      this.setStickyVideo(element, isStickyCloseable, videoFrame)

      window.addEventListener('scroll', () => {
        const windowWidth = window.innerWidth
        if (windowWidth > 640 && !isMobile.any() && !isVideoClosed) {
          this.setStickyVideo(element, isStickyCloseable, videoFrame)
        }
      })
    }

    handleVoomlyAPI() {
      const voomlyEmbedBlock = document.querySelectorAll('.voomly-embed-api')

      voomlyEmbedBlock.forEach(video => {
        const videoID = video.dataset.id
        const videoType = video.dataset.type
        const videoAspect = video.dataset.ratio

        const player = window.attachVoomlyPlayer(video, videoID, videoType, videoAspect)

        player.onReady(() => {
          player.enableCustomTurnstileIntegration();

          player.onTimelineTurnstileCompleted((body) => {
            if (body.email) {
              const filteredBody = {}

              Object.keys(body).forEach((key) => {
                if(key === 'firstName') filteredBody['first_name'] = body[key]
                if(key === 'lastName') filteredBody['last_name'] = body[key]
                if(key === 'email') filteredBody['email'] = body[key]
              })

              fetch(window.location, {
                method: 'post',
                headers: {
                  'Content-Type': 'application/json',
                  'X-CF2-POST-TYPE': 'submit'
                },
                body: JSON.stringify({ contact: filteredBody })
              })
            }
          });
        })
      });
    }

    setupVoomly(element) {
      if(window.attachVoomlyPlayer) {
        if(element.querySelector(".voomly-embed-api")) {
          this.handleVoomlyAPI()
        }
        this.sharedSetup(element);
      } else {
        document.addEventListener('CF2_VOOMLY_API_READY', () => {
          if(element.querySelector(".voomly-embed-api")) {
            this.handleVoomlyAPI()
          }
          this.sharedSetup(element);
        })
      }
    }

    setupYoutubeBP(element) {
      const frameId = this.setAndGetIFrameId(element)
      if (!frameId) return
      const bpPlayer = new window.YT.Player(frameId, {})
      this.handleExtraListeners(element, bpPlayer, () => {
        bpPlayer.seekTo(0, true)
        bpPlayer.unMute()
      })
    }

    setupYoutubeBPListeners(element) {
      const bpVideo = element.querySelector('.iframeblocked, .iframeunmuted')
      if (!bpVideo) return
      const iframe = bpVideo.querySelector('iframe')
      const srcAttr = iframe.getAttribute('data-src') ? 'data-src' : 'src'
      let src = iframe.getAttribute(srcAttr)
      if (src.indexOf('enablejsapi') < 0) {
        src = src.replace('autoplay=1', 'enablejsapi=1&autoplay=1')
        iframe.setAttribute(srcAttr, src)
      }
      if (VideoPlayerStarterV1.YoutubeApiReady) {
        if (!iframe.getAttribute('data-src')) this.setupYoutubeBP(element)
      } else {
        document.addEventListener('CF2_YOUTUBE_API_READY', () => {
          if (!iframe.getAttribute('data-src')) this.setupYoutubeBP(element)
        })
      }
    }

    setupVimeoBP(element) {
      const frameId = this.setAndGetIFrameId(element)
      if (frameId) {
        const bpPlayer = new window.Vimeo.Player(frameId)
        bpPlayer.ready().then(() => {
          this.handleExtraListeners(element, bpPlayer, () => {
            bpPlayer.setCurrentTime(0)
            bpPlayer.setVolume(0.5)
          })
        })
      }
    }

    checkVimeoReady(element) {
      const loopUntilVimeoReady = (timeout) => {
        timeout = timeout >= 300 ? timeout : timeout + 50
        const iframe = element.querySelector('iframe')
        if (!window.Vimeo) {
          window.setTimeout(loopUntilVimeoReady, timeout, timeout)
        } else {
          if (!iframe.getAttribute('data-src')) this.setupVimeoBP(element)
        }
      }

      loopUntilVimeoReady(50)
    }

    setupVimeoListeners(element) {
      const bpVideo = element.querySelector('.iframeblocked, .iframeunmuted')
      if (!bpVideo) return
      this.checkVimeoReady(element)
    }

    setupWistiaBP(element) {
      const setWistiaListener = (video) => {
        const bpPlayer = video
        const element = $(video.container).closest('.elVideoWrapper').get(0)
        this.handleExtraListeners(element, bpPlayer, () => {
          bpPlayer.play()
          bpPlayer.unmute()
        })
      }
      const wistiaId = element.getAttribute('data-wistia-id')
      globalThis._wq = globalThis._wq || []
      globalThis._wq.push({ id: wistiaId, onReady: setWistiaListener })
    }

    setupWistiaListeners(element) {
      const bpVideo = element.querySelector('.iframeblocked, .iframeunmuted')
      const iframe = element.querySelector('iframe')
      if (!bpVideo) return
      if (!iframe.getAttribute('data-src')) this.setupWistiaBP(element)
    }

    sharedSetup(element) {
      this.setupDefaultSize(element)
      this.setupVideoReSizing(element)
      this.setupStickyVideo(element)
      this.appendExtraChildren(element)
    }

    setupYoutube(element) {
      const url = window.location.href
      const queries = $.parseParams(url)
      if (queries['noautoplay'] == 'true') {
        let src = $('.elVideo iframe', $(element)).attr('src')
        src = src.replace('autoplay=1', 'autoplay=0')
        $('.elVideo iframe', $(element)).attr('src', src)
      }
      this.sharedSetup(element)
      this.setupYoutubeBPListeners(element)
    }

    setupVimeo(element) {
      const url = window.location.href
      const queries = $.parseParams(url)
      if (queries['noautoplay'] == 'true') {
        let src = $('.elVideo iframe', $(element)).attr('src')
        src = src.replace('autoplay=1', 'autoplay=0')
        $('.elVideo iframe', $(element)).attr('src', src)
      }
      this.sharedSetup(element)
      this.setupVimeoListeners(element)
    }

    setupWistia(element) {
      const url = window.location.href
      const queries = $.parseParams(url)
      if (queries['noautoplay'] == 'true') {
        let src = $('.elVideo iframe', this).attr('src')
        src = src.replace('autoPlay=1', 'autoPlay=0')
        $('.elVideo iframe', this).attr('src', src)
      }
      this.sharedSetup(element)
      this.setupWistiaListeners(element)
    }

    setupHTML5(element) {
      const video = element.querySelector('video')
      const id = video.id

      video.addEventListener('loadeddata', () => {
        const player = globalThis.videojs(id);
        player.fluid(true);
        player.playbackRates([0.5, 1, 1.5, 2]);
        player.aspectRatio('16:9');
      })
    }

    setupLazyLoaded(element) {
      var lazyVideo = element.querySelector('iframe[data-loading="lazy"], video[data-loading="lazy"]')
      const videoType = element.getAttribute('data-video-type')
      if (lazyVideo && "IntersectionObserver" in window) {
        if (lazyVideo.tagName == 'VIDEO') {
          lazyVideo.addEventListener('loadeddata', () => {
            delete element.querySelector('.elSpinnerWrapper')?.dataset?.loader
          })
        } else if (lazyVideo.tagName == 'IFRAME') {
          lazyVideo.addEventListener('load', () => {
            if (element.querySelector('.iframeblocked, .iframeunmuted')) {
              if (videoType === 'youtube') {
                this.setupYoutubeBP(element)
              } else if (videoType === 'vimeo') {
                this.setupVimeoBP(element)
              } else if (videoType === 'wistia') {
                this.setupWistiaBP(element)
              }
            }
            delete element.querySelector('.elSpinnerWrapper')?.dataset?.loader
          })        
        }

        var lazyVideoObserver = new IntersectionObserver((entries, observer) => {
          entries.forEach((el) => {
            const target = el.target
            if (el.isIntersecting) {
              if (target.tagName == 'IFRAME') {
                target.setAttribute('src', target.dataset.src)
                delete target.dataset.loading
                lazyVideoObserver.unobserve(lazyVideo);
              } else if (target.tagName == 'VIDEO') {
                for (var source in target.children) {
                  var videoSource = target.children[source];
                  if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE" && videoSource.dataset.src) {
                    videoSource.src = videoSource.dataset.src;
                  }
                }
                target.load();
                delete target.dataset.loading
                lazyVideoObserver.unobserve(lazyVideo);
              }
            }
          })
        })
        lazyVideoObserver.observe(lazyVideo);
      } else {
        delete element.querySelector('.elSpinnerWrapper')?.dataset?.loader
      }
    }
  }
  const videoPlayerStarter = VideoPlayerStarterV1.getInstance();
  export default videoPlayerStarter;
        

import VideoPlayerStarter from "./video-player-starter-v1"
import { CF2Component } from 'javascript/lander/runtime'

export default class VideoV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    VideoPlayerStarter.setupVideo(this.element)
  }



}

window["VideoV1"] = VideoV1

